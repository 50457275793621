<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="grid"
        title="관계사"
        tableId="grid"
        :columns="grid.columns"
        :data="grid.data"
        selection="multiple"
        rowKey="pjmProjectStackholderId"
        :isFullScreen="false"
        :editable="editable"
        :columnSetting="false"
        :filtering="false"
        :usePaging="false"
        gridHeight="350px"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable" label="추가" icon="add" @btnClicked="addrow" />
            <c-btn v-if="editable" label="저장" icon="save" @btnClicked="saveMat" />
            <c-btn v-if="editable" label="삭제" icon="remove" @btnClicked="deleteMat" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="grid2"
        title="도급업체 정보"
        tableId="grid2"
        :columns="grid2.columns"
        :data="grid2.data"
        selection="multiple"
        rowKey="pjmProjectVendorId"
        :isFullScreen="false"
        :editable="editable"
        :columnSetting="false"
        :filtering="false"
        :usePaging="false"
        gridHeight="350px"
        :plantCd="param.plantCd"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable" label="추가" icon="add" @btnClicked="addrow2" />
            <c-btn v-if="editable" label="저장" icon="save" @btnClicked="saveMat2" />
            <c-btn v-if="editable" label="삭제" icon="remove" @btnClicked="deleteMat2" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-bom',
  props: {
    param: {
      type: Object,
      default: () => ({
        plantCd: '',
        updateFlag: '',
      }),
    },
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [],
        data: [],
      },
      grid2: {
        columns: [],
        data: [],
      },
      editable: true,
      list1Url: '',
      save1rl: '',
      delete1Url: '',
      list2Url: '',
      save2rl: '',
      dele2e1Url: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
    'param.updateFlag': {
      handler: function () {
        this.getList1()
        this.getList2()
      },
      deep: false,
    }
  },
  methods: {
    init() {
      // role setting
      if(this.$store.getters.user.innerFlag === 'N') {
        this.editable = false;
      } else {
        this.editable = this.$route.meta.editable;
      }
      // url setting
      this.list1Url = selectConfig.pjm.stackholder.list.url;
      this.save1Url = transactionConfig.pjm.stackholder.save.url;
      this.delete1Url = transactionConfig.pjm.stackholder.delete.url;
      this.list2Url = selectConfig.pjm.vendor.list.url;
      this.save2Url = transactionConfig.pjm.vendor.save.url;
      this.delete2Url = transactionConfig.pjm.vendor.delete.url;
      
      this.$comm.getComboItems('PROJECT_STACKHOLDER_CD').then(_result => {
        this.grid.columns = [
          {
            name: 'projectStackholderCd',
            field: 'projectStackholderCd',
            label: '구분',
            required: true,
            valueName: 'codeName',
            align: 'center',
            style: "width:160px",
            sortable: false,
            type: 'select',
            comboItems: _result,
          },
          {
            name: 'companyName',
            field: 'companyName',
            label: '회사명',
            required: true,
            style: "width:350px",
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'ceoName',
            field: 'ceoName',
            label: '대표자',
            align: 'center',
            style: "width:120px",
            type: 'text',
            sortable: false,
          },
          {
            name: 'companyAddress',
            field: 'companyAddress',
            label: '주소',
            align: 'left',
            type: 'text',
            sortable: true,
          },
          {
            name: 'companyPhone',
            field: 'companyPhone',
            label: '전화번호',
            align: 'center',
            style: "width:130px",
            type: 'text',
            sortable: false,
          },
          {
            name: 'companyEmail',
            field: 'companyEmail',
            label: '이메일주소',
            align: 'center',
            style: "width:180px",
            type: 'text',
            sortable: false,
          },
          {
            name: 'managerName',
            field: 'managerName',
            label: '담당자',
            align: 'center',
            style: "width:120px",
            type: 'text',
            sortable: false,
          },
          {
            name: 'managerPhone',
            field: 'managerPhone',
            label: '담당자 연락처',
            align: 'center',
            style: "width:130px",
            type: 'text',
            sortable: false,
          },
        ]
      });
      this.grid2.columns = [
        {
          name: 'vendorName',
          field: 'vendorName',
          label: '업체명',
          required: true,
          align: 'center',
          style: "width:200px",
          sortable: false,
        },
        {
          name: 'processNames',
          field: 'processNames',
          label: '관련공종',
          align: 'left',
          style: "width:200px",
          sortable: false,
        },
        // {
        //   name: 'processCds',
        //   field: 'processCds',
        //   label: '관련공종',
        //   align: 'left',
        //   type: 'multiSelect',
        //   itemText: 'processName',
        //   itemValue: 'processCd',
        //   comboItems: _result.data,
        //   sortable: false,
        // },
        {
          name: 'contractDt',
          field: 'contractDt',
          label: '계약일',
          align: 'center',
          style: "width:120px",
          type: 'date',
          sortable: false,
        },
        // {
        //   name: 'contractPrice',
        //   field: 'contractPrice',
        //   label: '계약금액(원)',
        //   align: 'right',
        //   type: 'number',
        //   style: "width:150px",
        //   sortable: false,
        // },
        // {
        //   name: 'projectSafetyCost',
        //   field: 'projectSafetyCost',
        //   label: '안전관리비(원)',
        //   align: 'right',
        //   type: 'number',
        //   style: "width:150px",
        //   sortable: false,
        // },
        {
          name: 'constructionStartDt',
          field: 'constructionStartDt',
          label: '공사시작일',
          align: 'center',
          style: "width:120px",
          type: 'date',
          sortable: false,
        },
        {
          name: 'constructionEndDt',
          field: 'constructionEndDt',
          label: '공사종료일',
          align: 'center',
          style: "width:120px",
          type: 'date',
          sortable: false,
        },
        // {
        //   name: 'vendorRoleUser01Name',
        //   field: 'vendorRoleUser01Name',
        //   label: '현장책임자',
        //   align: 'left',
        //   sortable: false,
        //   style: 'width:130px',
        //   type: 'vendoruser',
        //   userId: 'vendorRoleUser01',
        //   vendorCd: 'vendorCd',
        //   disableTarget: 'selfFlag',
        //   disableCon: 'N',
        // },
        // {
        //   name: 'vendorRoleUser02Name',
        //   field: 'vendorRoleUser02Name',
        //   label: '안전관리책임자',
        //   align: 'left',
        //   sortable: false,
        //   style: 'width:130px',
        //   type: 'vendoruser',
        //   userId: 'vendorRoleUser02',
        //   vendorCd: 'vendorCd',
        //   disableTarget: 'selfFlag',
        //   disableCon: 'N',
        // },
        // {
        //   name: 'vendorRoleUser03Name',
        //   field: 'vendorRoleUser03Name',
        //   label: '안전관리자',
        //   align: 'left',
        //   sortable: false,
        //   style: 'width:130px',
        //   type: 'vendoruser',
        //   userId: 'vendorRoleUser03',
        //   vendorCd: 'vendorCd',
        //   disableTarget: 'selfFlag',
        //   disableCon: 'N',
        // },
        // {
        //   name: 'vendorRoleUser04Name',
        //   field: 'vendorRoleUser04Name',
        //   label: '안전관리보조원',
        //   align: 'left',
        //   sortable: false,
        //   style: 'width:130px',
        //   type: 'vendoruser',
        //   userId: 'vendorRoleUser04',
        //   vendorCd: 'vendorCd',
        //   disableTarget: 'selfFlag',
        //   disableCon: 'N',
        // },
      ]

      this.getList1();
      this.getList2();
    },
    getList1() {
      if (this.param.plantCd) {
        this.$http.url = this.$format(this.list1Url, this.param.plantCd);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid.data = _result.data;
        },);
      }
    },
    getList2() {
      if (this.param.plantCd) {
        this.$http.url = this.list2Url
        this.$http.type = 'GET';
        this.$http.param = {
          plantCd: this.param.plantCd
        };
        this.$http.request((_result) => {
          this.grid2.data = _result.data;
        },);
      }
    },
    addrow() {
      this.grid.data.push({
        editFlag: 'C',
        plantCd: this.param.plantCd,  // 공사현장코드
        pjmProjectStackholderId: uid(),  // 이해관계자 일련번호
        projectStackholderCd: null,  // 이해관계자 구분
        companyName: '',  // 회사명
        ceoName: '',  // 대표자
        companyAddress: '',  // 주소
        companyPhone: '',  // 전화번호
        companyEmail: '',  // 이메일주소
        managerName: '',  // 담당자
        managerPhone: '',  // 담당자연락처
      })
    },
    saveMat() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.save1Url;
            this.$http.type = 'POST';
            this.$http.param = this.grid.data;
            this.$http.request(() => {
              this.getList1();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    deleteMat() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.delete1Url);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList1();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.$refs['grid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addrow2() {
      this.popupOptions.title = '도급업체 검색'; // 도급업체 검색
      this.popupOptions.param = {
        type: 'multiple',
        noplantCd: true,
      };
      this.popupOptions.target = () => import(`${'@/pages/common/vendor/vendorPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if(this.$_.findIndex(this.grid2.data, { vendorCd: item.vendorCd }) === -1) {
            this.grid2.data.push({
              editFlag: 'C',
              plantCd: this.param.plantCd,  // 공사현장코드
              pjmProjectVendorId: uid(),  // 하도급 일련번호
              vendorCd: item.vendorCd,
              vendorName: item.vendorName,
              processCds: '',  // 관련공종코드_멀티
              contractDt: '',  // 계약일
              contractPrice: '',  // 계약금액
              constructionStartDt: '',  // 공사시작일
              constructionEndDt: '',  // 공사종료일
              vendorRoleUser01: '',  // 현장책임자
              vendorRoleUser01Name: '',  // 현장책임자
              vendorRoleUser02: '',  // 안전관리책임자
              vendorRoleUser02Name: '',  // 안전관리책임자
              vendorRoleUser03: '',  // 안전관리자
              vendorRoleUser03Name: '',  // 안전관리자
              vendorRoleUser04: '',  // 안전관리보조원
              vendorRoleUser04Name: '',  // 안전관리보조원
            })
          }
        });
      }
    },
    saveMat2() {
      if (this.$comm.validTable(this.grid2.columns, this.grid2.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.save2Url;
            this.$http.type = 'POST';
            this.$http.param = this.grid2.data;
            this.$http.request(() => {
              this.getList2();
              this.$emit('updateProjectInfo');
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    deleteMat2() {
      let selectData = this.$refs['grid2'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.delete2Url);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList2();
              this.$emit('updateProjectInfo');
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid2.data = this.$_.reject(this.grid2.data, item);
              })
              this.$refs['grid2'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>